import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Timeless Motors Retro Car Club Hakkında
			</title>
			<meta name={"description"} content={"Timeless Motors Retro Car Club, otomobillerin altın çağını kutlama vizyonuyla kurulmuştur. Kulübümüz sadece otomobillerle ilgili değildir - bir mirasa değer vermek ve retro araçların cazibesi ve tarihi ile büyülenen bireyleri bir araya getirmekle ilgilidir."} />
			<meta property={"og:title"} content={"Hakkımızda | Timeless Motors Retro Car Club Hakkında"} />
			<meta property={"og:description"} content={"Timeless Motors Retro Car Club, otomobillerin altın çağını kutlama vizyonuyla kurulmuştur. Kulübümüz sadece otomobillerle ilgili değildir - bir mirasa değer vermek ve retro araçların cazibesi ve tarihi ile büyülenen bireyleri bir araya getirmekle ilgilidir."} />
			<meta property={"og:image"} content={"https://ferandaturc.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ferandaturc.com/img/car-icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ferandaturc.com/img/car-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ferandaturc.com/img/car-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ferandaturc.com/img/car-icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ferandaturc.com/img/car-icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ferandaturc.com/img/car-icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="120px 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Text margin="0px 0px 20px 0px" color="--darkL2" font="normal 600 46px/1.2 --fontFamily-serifGaramond" text-align="center">
				Hakkımızda
			</Text>
			<Text margin="0px 0px 50px 0px" color="#333b41" font="normal 400 22px/1.5 --fontFamily-sans" text-align="center">
				Timeless Motors Retro Car Club, otomobillerin altın çağını kutlama vizyonuyla kurulmuştur. Kulübümüz sadece otomobillerle ilgili değildir - bir mirasa değer vermek ve retro araçların cazibesi ve tarihi ile büyülenen bireyleri bir araya getirmekle ilgilidir.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 0px 0px 25px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="normal 400 25px/1.5 --fontFamily-serifGaramond" color="#333b41">
							Retro otomobillerin bir makineden çok daha fazlası olduğuna inanıyoruz - onlar işçilik, stil ve yılmaz bir ruhu temsil eden geçmiş bir dönemin sembolleridir. Timeless Motors, bu makinelerin sadece hayranlıkla izlendiği değil, aynı zamanda anlaşıldığı ve gelecek nesiller için korunduğu bir yerdir.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://ferandaturc.com/img/5.jpg"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://ferandaturc.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Timeless Motors'u Özel Kılan Nedir?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Meraklılar Topluluğu - Kulübümüz, bilgi ve dostluk için ortak bir alan yaratan retro otomobil meraklıları, koleksiyoncular ve uzmanlardan oluşan canlı bir topluluktur.
Erkek Bağına Odaklanma - Timeless Motors, üyelerin rahat ve samimi bir atmosferde ortak ilgi alanları üzerinden bağ kurabilecekleri erkek merkezli bir ortam sunar.
				</Text>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" />
		</Components.Cta>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});